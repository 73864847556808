body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  header, footer {
    background-color: #f8f8f8;
    padding: 10px;
    text-align: center;
  }
  
  main {
    padding: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 10px;
  }
  
  input, button {
    margin-top: 5px;
  }

  @media (max-width: 600px) {
    header, footer {
      padding: 5px;
    }
  
    main {
      padding: 10px;
    }
  
    form {
      flex-direction: column;
    }
  
    label {
      margin-bottom: 5px;
    }
  }